import React from 'react'
import './styles.scss'

import TopMenu from '../TopMenu'

import brand from '../../assets/drawable-xxxhdpi/bg_celebe_logo.png'
import korean from '../../assets/drawable-xxxhdpi/korean_short_form.png'
import left1 from '../../assets/drawable-xxxhdpi/bg_1_jisu.png'
import short from '../../assets/drawable-xxxhdpi/short.png'
import form from '../../assets/drawable-xxxhdpi/form.png'

import google from '../../assets/drawable-xxxhdpi/btn-ggpl-2.png'
import apple from '../../assets/drawable-xxxhdpi/btn-apple-2.png'
// import onestore from '../../assets/drawable-xxxhdpi/ic_onestore.png'

export default () => (
    <div className="top-wrap">
        <TopMenu />
        <div className="main">
            <div className="back" />
            <img src={short} className="short" alt="" />
            <img src={form} className="form" alt="" />
            <div className="left-side">
            <div className="image-wrap">
                    <img src={left1} className="img1" alt="" />
                </div>
               
            </div>
            <div className="right-side">
            <div className="info">
                    <p className='title'>Mạng xã hội video ngắn</p>
                    <p className='title'>đến từ Hàn Quốc</p>
                    {/* <h2  >Video ngắn Hàn Quốc</h2> */}
                    {/* <h2>CELEBe</h2> */}
                    {/* <img src={korean} className="korean" alt="" />*/}
                    <img src={brand} className="logo" alt="" /> 

                    <div className="button-wrap">
                        <a
                            href="https://play.google.com/store/apps/details?id=io.celebe.vn"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={google} alt="" />
                        </a>
                        <a
                            href="https://apps.apple.com/vn/app/celebe/id1624411234"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={apple} alt="" />
                        </a>
                        
                    </div>
                </div>
            </div>
          
        </div>
    </div>
)