import React, { useState, useEffect } from 'react'
import './styles.scss'
import OtherTop from '../components/OtherTop'
import Footer from '../components/Footer'
import Up from '../assets/drawable-mdpi/ic_arrow_up_24.png'
import Down from '../assets/drawable-mdpi/ic_arrow_down_24.png'
import Axios from 'axios'

const Item = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        setIsOpen(false)
    }, [item])
    return (
        <div className="faq" onClick={() => setIsOpen(!isOpen)}>
            <div className="top">
                <span className="title">{item.questions}</span>
                <img src={isOpen ? Up : Down} />
            </div>
            {
                isOpen && <div className="content">
                    <pre>{item.answer}</pre>
                </div>
            }
        </div>
    )
}

function Faq() {
    const [categories, setCategories] = useState([])
    const [categoryIdx, setCategoryIdx] = useState(-10)
    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        Axios.get('http://api-server.celebe.vn/api/faq-category')
            .then((response) => {
                console.log(response)
                setCategories(response.data.faqCategories)
                setCategoryIdx(-1)
            })
    }, [])

    useEffect(() => {
        if (categoryIdx === -1) {
            let tmp = []
            for (const category of categories) {
                tmp = [...tmp, ...category.faqs]
            }
            setFaqs(tmp)
        }
        else if (categoryIdx >= 0) {
            setFaqs(categories[categoryIdx].faqs)
        }
    }, [categoryIdx])

    return (
        <div className="faq-wrap">
            <div className="in-wrap small">
                <OtherTop
                    title="Các câu hỏi thường gặp"
                    sub="Các bạn đang thắc mắc phần nào?"
                />
                {/* <div className="input-wrap">
                    <div className="input">
                        <input type="text" placeholder="검색어를 입력하세요." />
                        <button>
                            <img src={Search} />
                        </button>
                    </div>
                </div> */}

                <div className="category-wrap">
                    <button 
                        onClick={() => setCategoryIdx(-1)}
                        className={`category ${categoryIdx === -1 ? 'active' : ''}`}>Xem tất cả</button>
                    {
                        categories.map(
                            (item, index) => (
                                <button
                                    key={index}
                                    className={`category ${categoryIdx === index ? 'active' : ''}`}
                                    onClick={() => setCategoryIdx(index)}
                                >{item.name}</button>
                            )
                        )
                    }
                </div>

                <div className="count-wrap">
                    <p>Tổng cộng <strong>{faqs.length}</strong></p>
                </div>

                <div className="faq-list">
                    {
                        faqs.map(
                            (item, index) => <Item item={item} key={index} />
                        )
                    }
                </div>
                <Footer small />
            </div>
        </div>
    )
}

export default Faq
