import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Home, Faq, Request, Privacy, Service } from '../../pages'

export default () => {
    let location = useLocation()
    useEffect(() => {
        if (location.pathname !== '/privacy' && location.pathname !== '/service') {
            // const script = document.createElement('script')
            // script.async = true

            // try {
            //     if (window.Kakao) {
            //         window.Kakao.init('3fd9232e6cc90d45512d998d327f17e9')
            //     }
            // }
            // catch (e) { console.log(e) }

            // // window.Kakao.Channel.createChatButton({
            // //     container: '#kakao-talk-channel-chat-button',
            // //     channelPublicId: '_ZelRxb',
            // //     title: 'consult',
            // //     size: 'small',
            // //     color: 'yellow',
            // //     shape: 'mobile',
            // //     supportMultipleDensities: true
            // // })

            // document.body.appendChild(script)
            // document.body.removeChild(script)
        }

    }, [location])

    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/faq" component={Faq} />
            <Route path="/request" component={Request} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/service" component={Service} />
        </Switch>
    )
}