import React from 'react'
import './App.scss'
import Routes from './components/Routes'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

function App() {
    return (
        <CookiesProvider>
            <BrowserRouter>
                <div className="App">
                    <Routes />
                </div>
            </BrowserRouter>
        </CookiesProvider>
    )
}

export default App
