import React from 'react'
import './styles.scss'

import jisoo from '../../assets/drawable-xxxhdpi/video_jisoo.png'
import khung from '../../assets/drawable-xxxhdpi/khung-video.png'
import jisoo_video from '../../assets/drawable-xxxhdpi/LB-9-16.mp4'
import icons from '../../assets/drawable-xxxhdpi/video_icons.png'
import "../../../node_modules/video-react/dist/video-react.css"; // import css
import ReactPlayer from 'react-player';
import { InView } from "react-intersection-observer"

var player = false;

const imageClick = () => {
    console.log('Click');
    //player = true;
  } 
export default () => (
   
    //const self = this;
<InView onChange={(inView, entry) => {
            console.log('Inview:', inView)
        }}>
            {({ inView, ref, entry }) => (
    <div className="my-video-wrap"ref={ref} >
        <p>Sử dụng các hiệu ứng độc đáo</p>
        <h2 >tạo ra video của riêng bạn</h2>
        {/* {[{ src: jisoo_video }]} */}
        {/* http://celebe.vn/static/media/LB-9-16.mp4 */}
        {/* https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4 */}
                <ReactPlayer  url={[{ src: jisoo_video }]} playing={inView}
                    className='react-player' muted={true}
                    height="unset"
                    controls={true}
                />
           
        <img src={khung} className="jisoo" alt="" />
        <img src={khung} className="jisoo1" alt="" />
        <img src={icons} className="icons" alt="" onClick={() => {player = true;console.log("click",player);}} />
    </div>
     )}
     </InView>

)