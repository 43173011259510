import React, { useState } from 'react'
import './styles.scss'
import ham from '../../assets/drawable-xxxhdpi/ic_toggle.png'
import close from '../../assets/drawable-xxxhdpi/ic_close.png'
import brand from '../../assets/drawable-xxxhdpi/logo_logotype.png'
import { Link } from 'react-router-dom'

export default () => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <div className="menu-wrap">
                <a className="icon" onClick={() => setIsOpen(true)}><img src={ham} /></a>
            </div>
            {
                isOpen && <div className="menu">
                    <div className="inner">
                        <div className="top">
                            <Link to="/"><img src={brand} className="brand" /></Link>
                            <div />
                            <a className="icon" onClick={() => setIsOpen(false)}><img src={close} /></a>
                        </div>
                        <div className="bottom">
                            <ul>
                                <li><a target="_blank" rel="noreferrer" href="/privacy">Điều khoản dịch vụ</a></li>
                                <li><div className="rainbow" /></li>
                                <li><a target="_blank" rel="noreferrer" href="/service">Chính sách Quyền riêng tư</a></li>
                                <li><div className="rainbow" /></li>
                                <li><Link to="/faq">Câu hỏi thường gặp</Link></li>
                                {/* <li><div className="rainbow" /></li>
                                <li><Link to="/request">광고제휴문의</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}