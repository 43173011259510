import React from 'react'
import logo from '../../assets/drawable-xxxhdpi/bg_celebe_logo.png'
import korean from '../../assets/drawable-xxxhdpi/korean_short_form.png'
import jisoo from '../../assets/drawable-xxxhdpi/bg_3_jiso.png'
import google from '../../assets/drawable-xxxhdpi/btn-ggpl-2.png'
import apple from '../../assets/drawable-xxxhdpi/btn-apple-2.png'
// import logo from '../../assets/drawable-xxxhdpi/bg_celebe_logo.png'
import onestore from '../../assets/drawable-xxxhdpi/ic_onestore.png'
import './styles.scss'
import { Link } from 'react-router-dom'

export default ({ small }) => (
    <div className={`footer ${small ? 'small' : ''}`}>
        <div className="back">
            <img src={jisoo} className="jisoo" alt="" />
            <div className="background" />
        </div>
        {
            !small && <div className="content">
                <p className="title">Mạng xã hội video ngắn</p>
                <p className='title'>đến từ Hàn Quốc</p>
                {/* <p className="intro-botton">CELEBe</p> */}
                {/* <img src={korean} className="korean" alt="" /> */}
                <img src={logo} className="logo" alt="" />

                <div className="button-super">
                    <div className="button-wrap">
                        <a
                            href="https://play.google.com/store/apps/details?id=io.celebe.vn"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={google} alt="" />
                        </a>
                        <a
                            href="https://apps.apple.com/vn/app/celebe/id1624411234"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={apple} alt="" />
                        </a>
                        {/* <a
                            href="https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000756707"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={onestore} alt="" />
                        </a> */}
                    </div>
                </div>
            </div>
        }
        <div className="list-super">
            <div className="list-wrap pc">
                <ul>
                    <li><b>Copyright © 2021. Bản quyền thuộc về Công ty cổ phần CELEBe Việt Nam</b></li>
                    <li className="bar">&nbsp;</li>
                    <li><Link target="_blank" to="/privacy">Điều khoản dịch vụ</Link></li>
                    <li className="bar">|</li>
                    <li><Link target="_blank" to="/service">Chính sách Quyền riêng tư</Link></li>
                    <li className="bar">|</li>
                    <li><Link to="/faq">Câu hỏi thường gặp</Link></li>
                    {/* <li className="bar">|</li>
                <li><Link to="/request">광고제휴문의</Link></li> */}
                </ul>
            </div>
            <div className="list-wrap">
                <ul>
                    <li>Tầng 02, tòa nhà Green Pearl, 378 đường Minh Khai, phường Vĩnh Tuy, quận Hai Bà Trưng, Hà Nội</li>
                    <li className="bar">|</li>
                    <li>Email: cs@celebe.vn</li>
                </ul>
            </div>
        </div>
        <div className="kakao-button" id="kakao-talk-channel-chat-button" />
    </div>
)