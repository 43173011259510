import React from 'react'
import './styles.scss'
import brand from '../../assets/drawable-xxxhdpi/bg_celebe_logo.png'
import { Link } from 'react-router-dom'
import TopMenu from '../TopMenu'

export default ({ title, sub }) => (
    <div className="other-top-wrap">
        <TopMenu />
        <Link className="brand" to="/"><img src={brand} alt="" /></Link>
        <div className="content">
            <h1>{title}</h1>
            <hr />
            <pre>{sub}</pre>
        </div>
    </div>
)