import React from 'react'
import './styles.scss'
import coin from '../../assets/drawable-xxxhdpi/benefit_coin.png'
import jisoo from '../../assets/drawable-xxxhdpi/benefit_jisoo.png'
import benefit from '../../assets/drawable-xxxhdpi/benefit.png'

export default () => (
    <div className="benefit-wrap">
        <p>Bên cạnh muôn vàn content hấp dẫn</p>
        <h2>Chương trình hỗ trợ kiếm tiền</h2>
        <div className="image-wrap">
            <img src={coin} className="coin" alt="" />
            <img src={jisoo} className="jisoo" alt="" />
            <img src={benefit} className="benefit" alt="" />
        </div>
    </div>
)