import './styles.scss'
import Top from '../components/Top'
// import Tags from '../components/Tags'
// import Profit from '../components/Profit'
import Footer from '../components/Footer'
import MyVideo from '../components/MyVideo'
import Challenge from '../components/Challenge'
import Benefit from '../components/Benefit'
// import Popup from '../components/Popup'
// import {NetworkInfo} from 'react-native-network-info';



function Home() {
    // NetworkInfo.getIPV4Address().then(ipv4Address => {
    //     console.log('ipv4Address',ipv4Address)
    // })
    // setStateHandler();
     console.log("avc")
    return (
        
        <div className="home-wrap">
            <div className="in-wrap">
                <Top />
                {/* <Tags />cd 
                <Profit /> */}
                <MyVideo />
                <Challenge />
                <Benefit />
                <Footer />
                {/* <Popup /> */}
            </div>
        </div>
    )
}

export default Home
