import React from 'react'
import './styles.scss'
import group from '../../assets/drawable-xxxhdpi/challenge_group.png'
import app from '../../assets/drawable-xxxhdpi/challenge_app.png'

export default () => (
    <div className="challenge-wrap">
        <p>Cùng người nổi tiếng</p>
        <h2>Tham gia các thử thách siêu dễ và siêu vui</h2>
        <div className="image-wrap">
            <div className="group">
                <img src={group} alt="" />
            </div>
            <img src={app} className="app" alt="" />
        </div>
    </div>
)